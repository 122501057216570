// Shadows (originally from mdl http://www.getmdl.io/)

import { blackColor, dangerColor, grayColor, infoColor, primaryColor, roseColor, successColor, warningColor } from "components/styles/constants"
import { hexToRgb } from "components/styles/util"

const umbraOpacity = 0.2
const penumbraOpacity = 0.14
const ambientOpacity = 0.12

const black = hexToRgb(blackColor)

export default {
  shadowFocus: `0 0 8px rgba(${black}, 0.18), ` +
    `0 8px 16px rgba(${black}, 0.36)`,
  shadow2dp: `0 2px 2px 0 rgba(${black}, ${penumbraOpacity}),` +
    `0 3px 1px -2px rgba(${black}, ${umbraOpacity}),` +
    `0 1px 5px 0 rgba(${black}, ${ambientOpacity})`,
  shadow3dp: `0 3px 4px 0 rgba(${black}, ${penumbraOpacity}),` +
    `0 3px 3px -2px rgba(${black}, ${umbraOpacity}),` +
    `0 1px 8px 0 rgba(${black}, ${ambientOpacity})`,
  shadow4dp: `0 4px 5px 0 rgba(${black}, ${penumbraOpacity}),` +
    `0 1px 10px 0 rgba(${black}, ${ambientOpacity}),` +
    `0 2px 4px -1px rgba(${black}, ${umbraOpacity})`,
  shadow6dp: `0 6px 10px 0 rgba(${black}, ${penumbraOpacity}),` +
    `0 1px 18px 0 rgba(${black}, ${ambientOpacity}),` +
    `0 3px 5px -1px rgba(${black}, ${umbraOpacity})`,
  shadow8dp: `0 8px 10px 1px rgba(${black}, ${penumbraOpacity}),` +
    `0 3px 14px 2px rgba(${black}, ${ambientOpacity}),` +
    `0 5px 5px -3px rgba(${black}, ${umbraOpacity})`,
  shadow16dp: `0 16px 24px 2px rgba(${black}, ${penumbraOpacity}),` +
    `0 6px 30px 5px rgba(${black}, ${ambientOpacity}),` +
    `0 8px 10px -5px rgba(${black}, ${umbraOpacity})`,
  shadow24dp: `0 9px 46px 8px rgba(${black}, ${penumbraOpacity}),` +
    `0 11px 15px -7px rgba(${black}, ${ambientOpacity}),` +
    `0 24px 38px 3px rgba(${black}, ${umbraOpacity})`,
  shadowDefault: `0 10px 30px -12px rgba(${black}, 0.42),` +
    `0 4px 25px 0px rgba(${black}, ${ambientOpacity}),` +
    `0 8px 10px -5px rgba(${black}, ${umbraOpacity})`,
  innerShadow: `inset 0px -4px 3px -1px rgba(${black},0.15)`,
  button: color => `0 2px 2px 0 rgba(${color}, ${penumbraOpacity}), `+
    `0 3px 1px -2px rgba(${color}, ${umbraOpacity}), ` + 
    `0 1px 5px 0 rgba(${color}, ${ambientOpacity})`,
  buttonFocus: color => `0 14px 26px -12px rgba(${color}, 0.42),` +
    `0 4px 23px 0px rgba(${black}, ${ambientOpacity}),` +
    `0 8px 10px -5px rgba(${color}, ${umbraOpacity})`,
  box: color => `0 12px 20px -10px rgba(${color}, 0.28),` +
    `0 4px 20px 0px rgba(${black}, ${ambientOpacity}),` +
    `0 7px 8px -5px rgba(${color}, ${umbraOpacity})`,
  card: `0 2px 2px 0 rgba(${black}, ${penumbraOpacity}),` +
    `0 3px 1px -2px rgba(${black}, ${umbraOpacity}),` +
    `0 1px 5px 0 rgba(${black}, ${penumbraOpacity})`,
  cardRaised: `0 16px 38px -12px rgba(${black}, 0.56),` +
    `0 4px 25px 0px rgba(${black}, ${ambientOpacity}),` +
    `0 8px 10px -5px rgba(${black}, ${umbraOpacity})`,
  drawer: `0 4px 20px 0px rgba(${black}, ${penumbraOpacity}),` +
    `0 7px 12px -5px rgba(${hexToRgb(grayColor[9])}, 0.46)`,
  header: `0 4px 18px 0px rgba(${black}, 0.12),`+
    `0 7px 10px -5px rgba(${black}, 0.15)`,
  headerInv: `0px 10px 14px 15px rgba(${black},0.75)`,
  colors: {
    primary: { a: primaryColor[4], b: primaryColor[5] },
    rose: { a: roseColor[3], b: roseColor[4] },
    info: { a: infoColor[6], b: infoColor[7] },
    success: { a: successColor[6], b: successColor[7] },
    warning: { a: warningColor[6], b: warningColor[7] },
    danger: { a: dangerColor[6], b: dangerColor[7] },
    black: { a: blackColor, b: grayColor[2] },
  }
}