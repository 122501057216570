import { IChildren } from 'components/styles/interface'
import React, { FC, memo } from 'react'

import {
  DrawerDarkWrapper,
  DrawerStyled,
} from './styled'

export interface IDrawerProps extends IChildren {
  open: boolean;
  anchor: 'top' | 'bottom';
  onClose: () => void;
}

const DrawerMemo: FC<IDrawerProps> = ({
  open,
  anchor,
  onClose,
  children,
  ...props
}) => {

  return (
    <DrawerStyled
      open={open}
      anchor={anchor}
      onClose={onClose}
      {...props}
      ><DrawerDarkWrapper>{ children }</DrawerDarkWrapper>
    </DrawerStyled>
  )
}

export const Drawer = memo(DrawerMemo)


