/*eslint-disable*/
import React, { useState } from "react";

import { Drawer } from "components/drawer/Drawer";
import { BubbleStyled, TextNameStyled } from "../styled";
import { Text1Styled } from "./styled";

export default function SectionKodo({ absolute = false }) {
  const [ open, setOpen ] = useState(false)
  const toggle = () => setOpen(!open)

  return (
    <>
      <BubbleStyled absolute={absolute} onClick={toggle}>
        Espíritu Kodowari
      </BubbleStyled>
      <Drawer anchor='bottom' open={open} onClose={toggle}>
        <Text1Styled>
          Se suele traducir como:<br /> <b>“compromiso”</b> o <b>“empeño”</b>
        </Text1Styled>
        <br />
        <p>
          …el <b>Kodowari</b> es un hito personal al que el individuo se mantiene fiel
          …se trata del nivel de calidad o de la profesionalidad del individuo.
        </p>
        <p>
          Es una <b>actitud</b> a lo largo de la vida que constituye el elemento central del <b>Ikigai</b> (razón para levantarte cada mañana).
        </p>
        <p>El <b>Kodowari</b> es de naturaleza personal y una manifestación de orgullo por lo que uno hace.
          Es un enfoque basado en poner los cinco sentidos hasta el más pequeño detalle.
        </p>
        <p>De los 5 principios de Ikigai, Kodowari es el primero: <b>“empezar con humildad”</b>.
        </p>
        <TextNameStyled>
          Ken Mogi, “Ikigai Esencial”
        </TextNameStyled>
      </Drawer>
    </>
  );
}

